
import styled from "styled-components";
import Wrapper from "../../components/wrapper";
import { Helmet } from 'react-helmet';
import { Alert } from "react-bootstrap";
import theme from "../../constants/theme";
import { Tooltip } from "react-tooltip";
import { FaArrowDown } from "react-icons/fa";

const Content = styled.div`
`;

const H1 = styled.h1`
    padding-bottom: 24px; 
    @media(max-width: 900px){
        font-size: 24px;
    }
    @media(max-width: 600px){
        line-height: 28px;
    }
`;

const Section = styled.div`
    padding-bottom: 30px;
`;

const SectionHead = styled.div`
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 9px;
`;

const SectionBody = styled.div`
    @media(max-width: 600px){
        font-size: 14px;
    }
`;


const List = styled.ul`
    margin-bottom: 0;
`;

const Item = styled.li`

    @media(max-width: 600px){
        font-size: 14px;
    }
`;

const Paragraf = styled.div<{ nopadding?: boolean }>`
    padding-bottom: ${props => props.nopadding ? '0' : '24px'};

    @media(max-width: 600px){
        font-size: 14px;
    }
`;


const ListCourse = styled.div`
    display: flex; 
    justify-content: start;
    align-items: center;
    gap: 16px;  
    flex-direction: row;
    flex-wrap: wrap;
    margin: 24px 0;

    @media (max-width: 600px){
        gap: 8px;
    }
`;

const Course = styled.div`
    width: fit-content;
    padding: 16px;
    background-color: #fff;
    border-radius: 12px;

    @media (max-width: 600px){
        padding: 8px;
    }
`;

const CourseHead = styled.div`
    font-weight: bold;
    font-size: 18;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
`;

const CourseItem = styled.div``;

const CourseName = styled.div``;

const Link = styled(Alert.Link)`
    color: ${theme.colors.link};
`;

const CourseDownload = styled.div`
    background-color: #C82D2B;
    border-radius: 50%;
    height: 28px;
    width: 28px;
    display: flex;
    justify-content: center;     
    align-items: center;
    cursor: pointer;

    > svg {
        fill: white !important;
    }
`;



function OfertaEducativaCFGS() {
    return (
        <Content >
            <Helmet>
                <title>Cicles Formatius de Grau Superior</title>
                <meta name="description" content="Cicles Formatius de Grau Superior" />
            </Helmet>
            <Wrapper padding="60px 30px 60px">
                <H1>Cicles Formatius de Grau Superior</H1>
                <Paragraf>
                    La <b>formació professional</b> capacita per a l'exercici qualificat de diverses professions i proporciona la formació necessària per adquirir en competències professionals i els coneixements propis de cada sector, així com les competències transversals (capacitat de resolució de problemes, organització del treball, responsabilitat, treball en equip, autonomia, organització, iniciativa) que permeten mantenir un lloc de treball.
                </Paragraf>
                <Section>
                    <SectionHead>Organització</SectionHead>
                    <SectionBody>
                        Els cicles s'agrupen en famílies professionals i tenen una durada de dos cursos acadèmics: n'hi ha de 1400 i de 2000 hores. Una part d'aquestes hores es destina a la formació en un centre educatiu i una altra part a la formació pràctica en centres de treball.
                    </SectionBody>
                </Section>
                <Section>
                    <SectionHead>Accés al Grau Superior</SectionHead>
                    <SectionBody>
                        <Paragraf>
                            Per accedir als Cicles Formatius de Grau Superior s’ha de disposar d’un dels requisits següents
                        </Paragraf>
                        <List>
                            <Item>Títol de Batxillerat.</Item>
                            <Item>Títol de tècnic superior (CFGS) o tècnic especialista (FPII).</Item>
                            <Item>Titulació universitària o equivalent.</Item>
                            <Item>Títol de tècnic (CFGM).</Item>
                            <Item>Haver superat la prova d’accés als cicles formatius de grau superior. </Item>
                            <Item>Haver superat el curs d’orientació universitària (COU) o equivalent.</Item>
                            <Item>Haver superat la prova d’accés a la universitat per a majors de 25 anys.</Item>
                            <Item>Altres estudis equivalents a efectes acadèmics.</Item>
                        </List>
                    </SectionBody>
                </Section>
                <Section>
                    <SectionHead>Currículum</SectionHead>
                    <SectionBody>
                        <Paragraf>
                            El currículum consta de mòduls relacionats amb l’especialitat professional i la formació laboral. Es realitzen aproximadament 500 hores de pràctiques de formació obligatòries a les empreses del sector mitjançant convenis, DUAL GENERAL.
                        </Paragraf>
                        <Paragraf nopadding>
                            A L’EMT promovem l'emprenedoria per impulsar la iniciativa i la capacitat d'innovació de l’alumnat, mentre integrem tecnologies de la Indústria 4.0 per garantir la seva preparació per a les necessitats del mercat laboral modern i futur.
                        </Paragraf>
                    </SectionBody>
                </Section>
                <Section>
                    <SectionHead>DUAL INTENSIVA:</SectionHead>
                    <SectionBody>
                        <Paragraf>
                            La formació professional dual intensiva és una modalitat de l’FP que combina l’aprenentatge en una empresa amb la formació acadèmica en el centre educatiu.

                        </Paragraf>
                        <Paragraf>
                            Es faran aproximadament <b>1000 hores</b> de formació a l’empresa.
                        </Paragraf>
                        <Paragraf nopadding>
                            La formació a l’empresa serà remunerada i amb alta a la Seguretat Social.
                            Els alumnes que no realitzin la formació en modalitat dual, faran les pràctiques a l’empresa i tota la formació al centre en formació Dual general.
                        </Paragraf>
                    </SectionBody>
                </Section>
                <Section>
                    <SectionHead>Titulació</SectionHead>
                    <SectionBody>
                        Amb la superació d'un cicle formatiu de grau superior s'obté el títol de tècnic superior.
                    </SectionBody>
                </Section>
                <Section>
                    <SectionHead>Cicles formatius de Grau Superior (CFGS)</SectionHead>
                    <SectionBody>
                        <Paragraf nopadding>
                            Impartim els següents cicles formatius que tenen una durada de dos cursos acadèmics en horari de tarda:
                        </Paragraf>
                        <ListCourse>
                            <Course className="cfgs1">
                                <CourseHead>
                                    <CourseName>
                                        Administració i finances
                                    </CourseName>
                                    <CourseDownload onClick={() => window.open("https://drive.google.com/file/d/1kyZqYtoiHDapmOc5_JoMj9xhMrUKAf83/view?usp=sharing")}>
                                        <FaArrowDown />
                                    </CourseDownload>
                                </CourseHead>
                                <Tooltip anchorSelect=".cfgs1" place="top">
                                    <CourseItem>Família d'administració i gestió</CourseItem>
                                    <CourseItem>DUAL GENERAL/INTENSIVA</CourseItem>
                                </Tooltip>
                            </Course>
                            <Course className="cfgs2">
                                <CourseHead>
                                    <CourseName>
                                        Automoció
                                    </CourseName>
                                    <CourseDownload onClick={() => window.open("https://drive.google.com/file/d/1-PkAv2M4d3tN0b3GDxA1kEAHkygiWEnB/view?usp=sharing")}>
                                        <FaArrowDown />
                                    </CourseDownload>
                                </CourseHead>
                                <Tooltip anchorSelect=".cfgs2" place="top">
                                    <CourseItem>Família transport i manteniment de vehicles</CourseItem>
                                    <CourseItem>DUAL GENERAL/INTENSIVA</CourseItem>
                                </Tooltip>
                            </Course>
                            <Course className="cfgs3">
                                <CourseHead>
                                    <CourseName>
                                        Automatització i robòtica industrial
                                    </CourseName>
                                    <CourseDownload onClick={() => window.open("https://drive.google.com/file/d/1budzSwe9cuaXoDpzamTln6oYlvziFDHP/view?usp=sharing")}>
                                        <FaArrowDown />
                                    </CourseDownload>
                                </CourseHead>
                                <Tooltip anchorSelect=".cfgs3" place="top">
                                    <CourseItem>Família d'electricitat i electrònica</CourseItem>
                                    <CourseItem>DUAL GENERAL/INTENSIVA</CourseItem>
                                </Tooltip>
                            </Course>
                            <Course className="cfgs4">
                                <CourseHead>
                                    <CourseName>
                                        Laboratori d'anàlisi i control de qualitat
                                    </CourseName>
                                    <CourseDownload onClick={() => window.open("https://drive.google.com/file/d/16RZCCRJxTVFcyYYJyxORmdbqBMWKeY9k/view?usp=sharing")}>
                                        <FaArrowDown />
                                    </CourseDownload>
                                </CourseHead>
                                <Tooltip anchorSelect=".cfgs4" place="top">
                                    <CourseItem>Família de química</CourseItem>
                                    <CourseItem>DUAL GENERAL/INTENSIVA</CourseItem>
                                </Tooltip>
                            </Course>
                            <Course className="cfgs5">
                                <CourseHead>
                                    <CourseName>
                                        Programació de la producció en fabricació mecànica
                                    </CourseName>
                                    <CourseDownload onClick={() => window.open("https://drive.google.com/file/d/17QnyZxCI5XaTSgGgiBSgBSfhDNoYwS4z/view?usp=sharing")}>
                                        <FaArrowDown />
                                    </CourseDownload>
                                </CourseHead>
                                <Tooltip anchorSelect=".cfgs5" place="top">
                                    <CourseItem>Família de fabricació mecànica</CourseItem>
                                    <CourseItem>DUAL GENERAL/INTENSIVA</CourseItem>
                                </Tooltip>
                            </Course>
                            <Course className="cfgs6">
                                <CourseHead>
                                    <CourseName>
                                        Disseny en fabricació mecànica
                                    </CourseName>
                                    <CourseDownload onClick={() => window.open("https://drive.google.com/file/d/1xvv4YUItsZmvEfEAEyl-PjPQ-z7Gt0_3/view?usp=sharing")}>
                                        <FaArrowDown />
                                    </CourseDownload>
                                </CourseHead>
                                <Tooltip anchorSelect=".cfgs6" place="top">
                                    <CourseItem>Família de fabricació mecànica</CourseItem>
                                    <CourseItem>DUAL GENERAL/INTENSIVA</CourseItem>
                                </Tooltip>
                            </Course>
                            <Course className="cfgs7">
                                <CourseHead>
                                    <CourseName>
                                        Laboratori clínic i biomèdic
                                    </CourseName>
                                    <CourseDownload onClick={() => window.open("https://drive.google.com/file/d/1UvqUPjp50S7aS9dQKsxd7y0KiqRAykDo/view?usp=sharing")}>
                                        <FaArrowDown />
                                    </CourseDownload>
                                </CourseHead>
                                <Tooltip anchorSelect=".cfgs7" place="top">
                                    <CourseItem>Família de sanitat</CourseItem>
                                    <CourseItem>DUAL GENERAL/INTENSIVA</CourseItem>
                                </Tooltip>
                            </Course>
                        </ListCourse>
                    </SectionBody>
                </Section>
                <Paragraf>
                    Tens més dubtes? Consulta tota la informació sobre els cicles formatius de grau superior a <Link href="https://triaeducativa.gencat.cat/ca/fp/grau-superior/" target="_blank">triaeducativa.gencat.cat</Link>.
                </Paragraf>
            </Wrapper>
        </Content>
    );
}

export default OfertaEducativaCFGS;