
import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import { ItinerariEnum, RoleEnum } from '../../../constants/enums';
import { UserContext } from '../../../context/user-provider';
import { ConfigApi } from '../../../api/config';
import { IPreinscripcioConfig } from '../../../types/Config';

const Content = styled.div`
`;

const CheckboxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px; 
    width: fit-content;
    > div {
        top:0;
    }
    > label {
        margin-bottom: initial;
    }
`;

const Wrap = styled.div`
    display: flex;
    flex-direction: row;
`;

const Checkbox1 = styled.input``;

export interface ChecksProps {
    itinerari: ItinerariEnum;
    preinscripcioConfig: IPreinscripcioConfig | null;
}

function Checks(props: ChecksProps) {
    const user = useContext(UserContext);
    const [preinscripcio, setPreinscripcio] = useState<IPreinscripcioConfig | null>(props.preinscripcioConfig);

    useEffect(() => {
        setPreinscripcio(props.preinscripcioConfig);
    }, [props.preinscripcioConfig]);

    const handle = () => {
        if (props.itinerari === ItinerariEnum?.eso) handleESO();
        if (props.itinerari === ItinerariEnum?.batxillerat) handleBatxillerat();
        if (props.itinerari === ItinerariEnum?.cfgm) handleCFGM();
        if (props.itinerari === ItinerariEnum?.cfgs) handleCFGS();
    }

    const checked = () => {
        if (props.itinerari === ItinerariEnum?.eso) return preinscripcio?.ESO;
        if (props.itinerari === ItinerariEnum?.batxillerat) return preinscripcio?.Batxillerat;
        if (props.itinerari === ItinerariEnum?.cfgm) return preinscripcio?.CFGM;
        if (props.itinerari === ItinerariEnum?.cfgs) return preinscripcio?.CFGS;
    }

    const handleESO = () => {
        setPreinscripcio(prevState => prevState ? { ...prevState, ESO: !prevState.ESO } : null)
        ConfigApi.UpdatePreinscripcio("preinscripcio", { ESO: !preinscripcio?.ESO })
            .catch(x => console.log(x));
    }

    const handleBatxillerat = () => {
        setPreinscripcio(prevState => prevState ? { ...prevState, Batxillerat: !prevState.Batxillerat } : null)
        ConfigApi.UpdatePreinscripcio("preinscripcio", { Batxillerat: !preinscripcio?.Batxillerat })
            .catch(x => console.log(x));
    }

    const handleCFGM = () => {
        setPreinscripcio(prevState => prevState ? { ...prevState, CFGM: !prevState.CFGM } : null)
        ConfigApi.UpdatePreinscripcio("preinscripcio", { CFGM: !preinscripcio?.CFGM })
            .catch(x => console.log(x));
    }

    const handleCFGS = () => {
        setPreinscripcio(prevState => prevState ? { ...prevState, CFGS: !prevState.CFGS } : null)
        ConfigApi.UpdatePreinscripcio("preinscripcio", { CFGS: !preinscripcio?.CFGS })
            .catch(x => console.log(x));
    }

    return (<Content>
        {(user?.role === RoleEnum.superadmin || user?.role === RoleEnum.direccio) &&
            <Wrap>
                <CheckboxWrapper>
                    <Checkbox1
                        type="checkbox"
                        size={3}
                        checked={checked()}
                        onChange={handle}>
                    </Checkbox1>
                    <Form.Label>Actiu</Form.Label>
                </CheckboxWrapper>
            </Wrap>
        }
    </Content>);
}

export default Checks;
