
import styled from "styled-components";


export const ChildrenWrapper = styled.div<{ background?: string, padding?: string }>`
  max-width: 1244px;;
  margin: 0 auto;
  padding: ${props => !props.padding ? '30px 30px 0' : props.padding};
  width: 100%;
  background-color: ${props => !props.background ? 'transparent' : props.background};
  @media (max-width: 768px) { padding: 24px; }
  @media (max-width: 600px) { padding: 16px; }
  @media (max-width: 375px) { padding: 14px }
`;

export interface WrapperProps {
  children: React.ReactNode;
  backgroundColor?: string;
  padding?: string;
}

export default function Wrapper(props: WrapperProps) {
  return (<ChildrenWrapper
    background={props.backgroundColor}
    padding={props.padding}>
    {props.children}
  </ChildrenWrapper>);
}
