
import styled from 'styled-components';
import { useState } from 'react';
import { PreinscripcioList } from './list';
import { ItinerariEnum } from '../../../constants/enums';
import Box from '../../box';


const Content = styled.div``;

const ContentType = styled.div``;

const Menu = styled.div`
  display: flex;
  justify-content: start;
  align-items: center; 
  flex-direction: row;
  gap: 12px;
  background-color: #ffff;
  padding: 12px 24px;
  border-radius: 24px;
  width: fit-content;
  margin: 24px 0 12px;
`;

const Item = styled.div<{ selected: boolean }>`
  color: ${props => props.selected ? '#C82D2D' : '#212529'};
  font-weight: ${props => props.selected ? 'bold' : 'initial'};
  cursor: pointer;
`;


export function Preinscripcio() {
    const [menuSelected, setMenuSelected] = useState<ItinerariEnum>(ItinerariEnum.eso);

    return (<Content>
        <Menu>
            <Item
                selected={menuSelected === ItinerariEnum.eso}
                onClick={() => setMenuSelected(ItinerariEnum.eso)}>ESO</Item>
            <Item
                selected={menuSelected === ItinerariEnum.batxillerat}
                onClick={() => setMenuSelected(ItinerariEnum.batxillerat)}>Batxillerat</Item>
            <Item
                selected={menuSelected === ItinerariEnum.cfgm}
                onClick={() => setMenuSelected(ItinerariEnum.cfgm)}>CFGM</Item>
            <Item
                selected={menuSelected === ItinerariEnum.cfgs}
                onClick={() => setMenuSelected(ItinerariEnum.cfgs)}>CFGS</Item>
        </Menu>
        <Box background-color="#fff" padding="14px" margin="0 0 24px">
            <ContentType>
                <PreinscripcioList itinerari={menuSelected} />
            </ContentType>
        </Box>
    </Content>);
}

