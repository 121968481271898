import styled from "styled-components";
import { useState, useEffect } from "react";
import { NewsListApi } from "../../api/news";
import { INews } from "../../types/News";
import Wrapper from "../wrapper";
import { PreviewImage } from "../preview_image";
import theme from "../../constants/theme";

const Content = styled.div`
  margin-top: 24px;


`;

const Title = styled.h2`
  text-align: center;
  font-weight: bold; 
  color: #343a40;

  @media(max-width: 1024px){
    font-size: 28px;
    line-height: 28px;
  }

  @media(max-width: 600px){
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 24px;
  }
`;

const Sections = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 18px 0;
  margin: 0 auto;
  gap: 24px;

  @media(max-width: 600px){
    padding: 0;
    gap: 12px;
  } 
`;

const Section = styled.a`
  display: flex;
  flex-direction: row;
  align-items: start;
  background-color: #fff;
  border-radius: 12px;
  text-decoration: none;
  cursor: pointer;
  box-shadow: rgba(155, 155, 155, 0.33) 5px 6px 10px 0px;

  :hover{
    background-color: #ececec;
  }

  @media (max-width: 600px){
    padding: 0; 
    flex-direction: column;
  }
`;

const SectionText = styled.div`
  width: calc(100% - 300px);
  display: flex;
  flex-direction: column;
  color: #343a40;
  text-decoration: none;
  padding: 24px;

  @media(max-width: 930px){
    width: calc(100% - 150px);
  }

  @media (max-width: 600px){
    padding: 16px;
    width: 100%;
  }

`;

const SectionImage = styled.div`
  cursor: pointer;
  position: relative;
  width: 300px;
  height: 100%;

  @media (max-width: 930px){
    width: 150px;
  }

  @media(max-width: 600px){
    width: 100%;
    height: 100px;
  }
`;

const ImageCover = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
`;

const SectionTitle = styled.div`
  display: flex;
  align-items: center; 
  justify-content: start;
`;

const SectionTitleText = styled.h3`
  font-weight: bold;
  text-align: left;
  margin-bottom: 0;

  @media(max-width: 600px){
    font-size: 18px;
  }
`;

const Link = styled.div`
  color: ${theme.colors.link};
  font-weight:bold; 
  font-size: 16px;
  text-decoration: underline;
`;

const SectionDescription = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @media(max-width: 600px){
    font-size: 14px
  }
`;

const SectionDate = styled.div`
  font-size: 14px;
  color: #a7a7a7;
`;

function News() {
  const [news, setNews] = useState<INews[] | null>([]);
  const [imageOpenId, setImageOpenId] = useState<string | null>(null);

  useEffect(() => {
    NewsListApi.GetById("list")
      .then((x: any) => {
        let sorted = x.list
          .filter(x => x.active === true)
          .sort((a, b) => a.order - b.order);
        setNews(sorted);
      })
      .catch(x => console.log(x))
    // eslint-disable-next-line
  }, []);

  if (!news || news.length === 0) return <></>;

  return (
    <Wrapper>
      <Content>
        <Title>Notícies</Title>
        <Sections>
          {news?.map((n: INews, index: number) =>
            <Section key={index} href={n.link} target="_blank">
              <SectionImage onClick={() => setImageOpenId(n.id)}>
                <ImageCover src={n.imageUrl} alt={n.image} />
              </SectionImage>
              <PreviewImage
                image={n.imageUrl}
                open={imageOpenId === n.id}
                onClose={() => setImageOpenId(null)} />
              <SectionText >
                <SectionTitle>
                  <SectionTitleText>{n.title}</SectionTitleText>
                </SectionTitle>
                {n.date !== null && <SectionDate >{new Date((n.date! as any).seconds * 1000).toLocaleDateString()}</SectionDate>}
                <SectionDescription>{n.description}</SectionDescription>
                <Link>Visita l'enllaç extern per saber més</Link>
              </SectionText>
            </Section>
          )}
        </Sections>
      </Content>
    </Wrapper >
  );
}

export default News;