
import { useContext, useState } from 'react';
import styled from 'styled-components';
import { Inici } from './inici/inici';
import { Users } from './users/users';
import { Preinscripcio } from './preinscripcio/preinscripcio';
import { UserContext } from '../../context/user-provider';
import { RoleEnum } from '../../constants/enums';
import Students from './alumnes-familia/students';


const Content = styled.div``;

const ContentType = styled.div``;

const Menu = styled.div`
  display: flex;
  justify-content: start;
  align-items: center; 
  flex-direction: row;
  gap: 12px;
  background-color: #ffff;
  padding: 12px 24px;
  border-radius: 24px;
  width: fit-content;
  margin: 24px 0;
`;

const Item = styled.div<{ selected: boolean }>`
  color: ${props => props.selected ? '#C82D2D' : '#212529'};
  font-weight: ${props => props.selected ? 'bold' : 'initial'};
  cursor: pointer;
`;

enum menu {
  inici,
  students,
  preinscripcio,
  users
}

function Main() {
  const user = useContext(UserContext);
  const [menuSelected, setMenuSelected] = useState<menu>(user?.role === RoleEnum.superadmin ? menu.inici : menu.preinscripcio);

  return (
    <Content>
      <Menu>
        {(user?.role === RoleEnum.superadmin || user?.role === RoleEnum.direccio) &&
          <Item
            selected={menuSelected === menu.inici}
            onClick={() => setMenuSelected(menu.inici)}>Inici</Item>
        }
        {(user?.role === RoleEnum.superadmin || user?.role === RoleEnum.direccio) &&
          <Item
            selected={menuSelected === menu.students}
            onClick={() => setMenuSelected(menu.students)}>Alumnat i familia</Item>
        }
        {(user?.role === RoleEnum.superadmin || user?.role === RoleEnum.direccio) &&
          <Item
            selected={menuSelected === menu.preinscripcio}
            onClick={() => setMenuSelected(menu.preinscripcio)}>Preinscripcions</Item>
        }
        {user?.role === RoleEnum.superadmin &&
          <Item
            selected={menuSelected === menu.users}
            onClick={() => setMenuSelected(menu.users)}>Users</Item>
        }
      </Menu>
      <ContentType>
        {menuSelected === menu.inici && <Inici />}
        {menuSelected === menu.preinscripcio && <Preinscripcio />}
        {menuSelected === menu.users && <Users />}
        {menuSelected === menu.students && <Students />}
      </ContentType>
    </Content>
  );
}

export default Main;
