
import styled from 'styled-components';
import { Button, Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { ConfigApi } from '../../../api/config';
import { IPreinscripcioConfig } from '../../../types/Config';
import theme from '../../../constants/theme';
import { ItinerariEnum } from '../../../constants/enums';

const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const FormWrapper = styled.div`
    display:flex;
    justify-content: center;
    align-items: initial;
    flex-direction: column;
    width: 100%;
`;

const FormItemWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    gap: 4px;
`;

const Label = styled.div`
    font-weight: bold;
    font-size: 14px;
    display: block;
    width: 100%;
`;

const ButtonStyled = styled(Button)`
    background-color: ${theme.colors.main};
    border: ${theme.colors.main};
    color: ${theme.colors.white};
    width: 200px;
`;

export interface InstruccionsProps {
    itinerari: ItinerariEnum;
    preinscripcioConfig: IPreinscripcioConfig | null;
}

function Instruccions(props: InstruccionsProps) {
    const [preinscripcio, setPreinscripcio] = useState<IPreinscripcioConfig | null>(props.preinscripcioConfig);

    useEffect(() => {
        setPreinscripcio(props.preinscripcioConfig);
    }, [props.preinscripcioConfig]);

    const handle = (value: string) => {
        if (props.itinerari === ItinerariEnum?.eso) handleESO(value);
        if (props.itinerari === ItinerariEnum?.batxillerat) handleBatxillerat(value);
        if (props.itinerari === ItinerariEnum?.cfgm) handleCFGM(value);
        if (props.itinerari === ItinerariEnum?.cfgs) handleCFGS(value);
    }

    const handleClick = () => {
        if (props.itinerari === ItinerariEnum?.eso) handleClickESO();
        if (props.itinerari === ItinerariEnum?.batxillerat) handleClickBatxillerat();
        if (props.itinerari === ItinerariEnum?.cfgm) handleClickCFGM();
        if (props.itinerari === ItinerariEnum?.cfgs) handleClickCFGS();;
    }

    const getValue = () => {
        if (props.itinerari === ItinerariEnum?.eso) return preinscripcio?.InstruccionsUrlESO;
        if (props.itinerari === ItinerariEnum?.batxillerat) return preinscripcio?.InstruccionsUrlBatxillerat;
        if (props.itinerari === ItinerariEnum?.cfgm) return preinscripcio?.InstruccionsUrlCFGM;
        if (props.itinerari === ItinerariEnum?.cfgs) return preinscripcio?.InstruccionsUrlCFGS;
    }

    const handleESO = (value: string) => {
        setPreinscripcio(prevState => prevState ? { ...prevState, InstruccionsUrlESO: value } : null)
    }

    const handleClickESO = () => {
        ConfigApi.UpdatePreinscripcio("preinscripcio", { InstruccionsUrlESO: preinscripcio?.InstruccionsUrlESO })
            .catch(x => console.log(x));
    }

    const handleBatxillerat = (value: string) => {
        setPreinscripcio(prevState => prevState ? { ...prevState, InstruccionsUrlBatxillerat: value } : null)
    }

    const handleClickBatxillerat = () => {
        ConfigApi.UpdatePreinscripcio("preinscripcio", { InstruccionsUrlBatxillerat: preinscripcio?.InstruccionsUrlBatxillerat })
            .catch(x => console.log(x));
    }

    const handleCFGM = (value: string) => {
        setPreinscripcio(prevState => prevState ? { ...prevState, InstruccionsUrlCFGM: value } : null);
    }

    const handleClickCFGM = () => {
        ConfigApi.UpdatePreinscripcio("preinscripcio", { InstruccionsUrlCFGM: preinscripcio?.InstruccionsUrlCFGM })
            .catch(x => console.log(x));
    }

    const handleCFGS = (value: string) => {
        setPreinscripcio(prevState => prevState ? { ...prevState, InstruccionsUrlCFGS: value } : null);
    }

    const handleClickCFGS = () => {
        ConfigApi.UpdatePreinscripcio("preinscripcio", { InstruccionsUrlCFGS: preinscripcio?.InstruccionsUrlCFGS })
            .catch(x => console.log(x));
    }

    return (<Content>
        <FormWrapper>
            <Label>Instruccions</Label>
            <FormItemWrapper>
                <Form.Control
                    as="textarea"
                    rows={1}
                    value={getValue()!}
                    onChange={e => handle(e.target.value)} />
                <ButtonStyled onClick={handleClick}>Desar</ButtonStyled>
            </FormItemWrapper>
        </FormWrapper>


    </Content>);
}

export default Instruccions;
