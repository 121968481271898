
import styled from "styled-components";
import Wrapper from "../../components/wrapper";
import { Helmet } from 'react-helmet';
import theme from "../../constants/theme";
import { Alert } from "react-bootstrap";
import { FaArrowDown } from "react-icons/fa";
import { Tooltip } from 'react-tooltip'

const Content = styled.div`
`;

const H1 = styled.h1`
    padding-bottom: 24px;
    @media(max-width: 900px){
        font-size: 24px;
    }
    @media(max-width: 600px){
        line-height: 28px;
    }
`;

const Section = styled.div`
    padding-bottom: 30px;
`;

const SectionHead = styled.div`
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 9px;
`;

const SectionBody = styled.div`
    @media(max-width: 600px){
        font-size: 14px;
    }
`;


const List = styled.ul`
    margin-bottom: 0;
`;

const Item = styled.li`

    @media(max-width: 600px){
        font-size: 14px;
    }
`;

const Paragraf = styled.div<{ nopadding?: boolean }>`
    padding-bottom: ${props => props.nopadding ? '0' : '24px'};

    @media(max-width: 600px){
        font-size: 14px;
    }
`;


const ListCourse = styled.div`
  display: flex; 
    justify-content: start;
    align-items: center;
    gap: 16px;  
    flex-direction: row;
    flex-wrap: wrap;
    margin: 24px 0;

    @media (max-width: 600px){
        gap: 8px;
    }
`;

const Course = styled.div`
    width: fit-content;
    padding: 16px;
    background-color: #fff;
    border-radius: 12px;


    @media (max-width: 600px){
        padding: 8px;
    }
`;

const CourseHead = styled.div`
    font-weight: bold;
    font-size: 18;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
`;

const CourseItem = styled.div``;

const CourseDownload = styled.div`
    background-color: #C82D2B;
    border-radius: 50%;
    height: 28px;
    width: 28px;
    display: flex;
    justify-content: center;     
    align-items: center;
    cursor: pointer;

    > svg {
        fill: white !important;
    }
`;

const CourseName = styled.div``;

const Link = styled(Alert.Link)`
    color: ${theme.colors.link};
`;


function OfertaEducativaCFGM() {
    return (
        <Content >
            <Helmet>
                <title>Cicles Formatius de Grau Mitjà</title>
                <meta name="description" content="Cicles Formatius de Grau Mitjà" />
            </Helmet>
            <Wrapper padding="60px 30px 60px">
                <H1>Cicles Formatius de Grau Mitjà</H1>
                <Paragraf>
                    La <b>formació professional</b> capacita per a l'exercici qualificat de diverses professions i proporciona la formació necessària per adquirir en competències professionals i els coneixements propis de cada sector, així com les competències transversals (capacitat de resolució de problemes, organització del treball, responsabilitat, treball en equip, autonomia, organització, iniciativa) que permeten mantenir un lloc de treball.
                </Paragraf>
                <Section>
                    <SectionHead>Organització</SectionHead>
                    <SectionBody>
                        Els cicles s'agrupen en famílies professionals i tenen una durada de dos cursos acadèmics: n'hi ha de 1400 i de 2000 hores. Una part d'aquestes hores es destina a la formació en un centre educatiu i una altra part a la formació pràctica en centres de treball.
                    </SectionBody>
                </Section>
                <Section>
                    <SectionHead>Accés al Grau Mitjà</SectionHead>
                    <SectionBody>
                        <Paragraf>
                            Per accedir als Cicles Formatius de Grau Mitjà s’ha de disposar d’un dels requisits següents:
                        </Paragraf>
                        <List>
                            <Item>Títol de Graduat en Educació Secundària Obligatòria (ESO).</Item>
                            <Item>Títol de tècnic (CFGM) o de tècnic auxiliar (FPI).</Item>
                            <Item>Haver superat el segon curs del batxillerat unificat polivalent (BUP).</Item>
                            <Item>Haver superat el curs específic per a l’accés als cicles de grau mitjà (CAM).</Item>
                            <Item>Haver superat la prova d’accés.</Item>
                            <Item>Haver superat els mòduls obligatoris d'un programa de qualificació professional inicial (PQPI).</Item>
                            <Item>Haver superat la formació professional bàsica (FPB).</Item>
                            <Item>Altres estudis equivalents a efectes acadèmics.</Item>
                        </List>
                    </SectionBody>
                </Section>
                <Section>
                    <SectionHead>Currículum</SectionHead>
                    <SectionBody>
                        <Paragraf>
                            El currículum consta de mòduls relacionats amb l’especialitat professional i la formació laboral. Es realitzen aproximadament 500 hores de pràctiques de formació obligatòries a les empreses del sector mitjançant convenis, DUAL GENERAL.
                        </Paragraf>
                        <Paragraf nopadding>
                            A L’EMT promovem l'emprenedoria per impulsar la iniciativa i la capacitat d'innovació de l’alumnat, mentre integrem tecnologies de la Indústria 4.0 per garantir la seva preparació per a les necessitats del mercat laboral modern i futur.
                        </Paragraf>
                    </SectionBody>
                </Section>
                <Section>
                    <SectionHead>DUAL INTENSIVA:</SectionHead>
                    <SectionBody>
                        <Paragraf>
                            La formació professional dual intensiva és una modalitat de l’FP que combina l’aprenentatge en una empresa amb la formació acadèmica en el centre educatiu.
                        </Paragraf>
                        <Paragraf>
                            Es faran aproximadament 1000 hores de formació a l’empresa.
                        </Paragraf>
                        <Paragraf nopadding>
                            La formació a l’empresa serà remunerada i amb alta a la Seguretat Social.
                            Els alumnes que no realitzin la formació en modalitat dual, faran les pràctiques a l’empresa i tota la formació al centre en formació Dual general.
                        </Paragraf>
                    </SectionBody>
                </Section>
                <Section>
                    <SectionHead>Titulació</SectionHead>
                    <SectionBody>
                        Amb la superació d'un cicle formatiu de grau mitjà s'obté el títol de tècnic.
                    </SectionBody>
                </Section>
                <Section>
                    <SectionHead>Cicles Formatius de Grau Mitjà (CFGM)</SectionHead>
                    <SectionBody>
                        <Paragraf nopadding>
                            Impartim els següents cicles formatius que tenen una durada de dos cursos acadèmics en horari intensiu de matí o tarda:
                        </Paragraf>
                        <ListCourse>
                            <Course className="cfgm1">
                                <CourseHead >
                                    <CourseName>
                                        Gestió administrativa
                                    </CourseName>
                                    <CourseDownload onClick={() => window.open("https://drive.google.com/file/d/1CdK0Q95bAF-16-3Tfqr2XjiNKmsD63Mi/view?usp=sharing")}>
                                        <FaArrowDown />
                                    </CourseDownload>
                                </CourseHead>
                                <Tooltip anchorSelect=".cfgm1" place="top">
                                    <CourseItem>Família d'administració i gestió</CourseItem>
                                    <CourseItem>DUAL GENERAL/INTENSIVA</CourseItem>
                                    <CourseItem>2 grups: 1 grup de matí / 1 grup de tarda</CourseItem>
                                </Tooltip>
                            </Course>
                            <Course className="cfgm2">
                                <CourseHead>
                                    <CourseName>
                                        Electromecànica de vehicles automòbils
                                    </CourseName>
                                    <CourseDownload onClick={() => window.open("https://drive.google.com/file/d/1ssnNuK3MuxGIiO3e3fL7XliO74g_iH-P/view?usp=sharing")}>
                                        <FaArrowDown />
                                    </CourseDownload>
                                </CourseHead>
                                <Tooltip anchorSelect=".cfgm2" place="top">
                                    <CourseItem>Família transport i manteniment de vehicles</CourseItem>
                                    <CourseItem>DUAL GENERAL/INTENSIVA</CourseItem>
                                    <CourseItem>2 grups: 1 grup de matí / 1 grup de tarda</CourseItem>

                                </Tooltip>
                            </Course>
                            <Course className="cfgm3">
                                <CourseHead>
                                    <CourseName>
                                        Instal·lacions elèctriques i automàtiques
                                    </CourseName>
                                    <CourseDownload onClick={() => window.open("https://drive.google.com/file/d/1fT79hn-BezMEUvMyVpiG_33hnf9xDvJz/view?usp=sharing")}>
                                        <FaArrowDown />
                                    </CourseDownload>
                                </CourseHead>
                                <Tooltip anchorSelect=".cfgm3" place="top">
                                    <CourseItem>Família d'electricitat i electrònica</CourseItem>
                                    <CourseItem>DUAL GENERAL/INTENSIVA</CourseItem>
                                    <CourseItem>1 grup: 1r curs matí, 2n curs tarda</CourseItem>
                                </Tooltip>
                            </Course>
                            <Course className="cfgm4">
                                <CourseHead>
                                    <CourseName>
                                        Manteniment electromecànic
                                    </CourseName>
                                    <CourseDownload onClick={() => window.open("https://drive.google.com/file/d/1AItcJJau4KSxxp-Thnn5pEanU_plVh7x/view?usp=sharing")}>
                                        <FaArrowDown />
                                    </CourseDownload>
                                </CourseHead>
                                <Tooltip anchorSelect=".cfgm4" place="top">
                                    <CourseItem>Família d'instal·lació i manteniment</CourseItem>
                                    <CourseItem>DUAL GENERAL/INTENSIVA</CourseItem>
                                    <CourseItem>1 grup: 1r curs matí, 2n curs tarda</CourseItem>
                                </Tooltip>
                            </Course>
                            <Course className="cfgm5">
                                <CourseHead>
                                    <CourseName>
                                        Mecanització
                                    </CourseName>
                                    <CourseDownload onClick={() => window.open("https://drive.google.com/file/d/1IUvpr_tq0EPz45FmhTU1uB42pIpD-xKd/view?usp=sharing")}>
                                        <FaArrowDown />
                                    </CourseDownload>
                                </CourseHead>
                                <Tooltip anchorSelect=".cfgm5" place="top">
                                    <CourseItem>Família de fabricació mecànica</CourseItem>
                                    <CourseItem>DUAL GENERAL/INTENSIVA</CourseItem>
                                    <CourseItem>2 grups: 1r curs matí, 2n curs tarda</CourseItem>
                                </Tooltip>
                            </Course>
                            <Course className="cfgm6">
                                <CourseHead>
                                    <CourseName>
                                        Operacions de laboratori
                                    </CourseName>
                                    <CourseDownload onClick={() => window.open("https://drive.google.com/file/d/1koRF1sBbdfIJR-Ege5iySQhJmbY_9Gwo/view?usp=sharing")}>
                                        <FaArrowDown />
                                    </CourseDownload>
                                </CourseHead>
                                <Tooltip anchorSelect=".cfgm6" place="top">
                                    <CourseItem>Família de química</CourseItem>
                                    <CourseItem>DUAL GENERAL/INTENSIVA</CourseItem>
                                    <CourseItem>1 grup: 1r curs matí, 2n curs tarda</CourseItem>
                                </Tooltip>
                            </Course>
                            <Course className="cfgm7">
                                <CourseHead>
                                    <CourseName>
                                        Cures auxiliars d'infermeria (LOGSE)
                                    </CourseName>
                                    <CourseDownload onClick={() => window.open("https://drive.google.com/file/d/1_o-0dCLF-gDZ8HTd8txV4FKGAQQ5onEt/view?usp=sharing")}>
                                        <FaArrowDown />
                                    </CourseDownload>
                                </CourseHead>
                                <Tooltip anchorSelect=".cfgm7" place="top">
                                    <CourseItem>Família de sanitat</CourseItem>
                                    <CourseItem>DUAL GENERAL/INTENSIVA</CourseItem>
                                    <CourseItem>4 grups: 2 grups de matí / 2 grups de tarda</CourseItem>
                                </Tooltip>
                            </Course>
                            <Course className="cfgm8">
                                <CourseHead>
                                    <CourseName>
                                        Emergències sanitàries
                                    </CourseName>
                                    <CourseDownload onClick={() => window.open("https://drive.google.com/file/d/1wQn__uDCQ3LCD67qRAiaFNFfhzyjdqsi/view?usp=sharing")}>
                                        <FaArrowDown />
                                    </CourseDownload>
                                </CourseHead>
                                <Tooltip anchorSelect=".cfgm8" place="top">
                                    <CourseItem>Família de sanitat</CourseItem>
                                    <CourseItem>DUAL GENERAL/INTENSIVA</CourseItem>
                                    <CourseItem>1 grup: 1r curs tarda, 2n curs tarda</CourseItem>
                                    <Link href="" target="_blank">Veure més informació</Link>
                                </Tooltip>
                            </Course>
                            <Course className="cfgm9">
                                <CourseHead>
                                    <CourseName>
                                        Xarxes i estacions de tractament d'aigües
                                    </CourseName>
                                    <CourseDownload onClick={() => window.open("https://drive.google.com/file/d/1SeJL3Zv6Ro-zgwt3qaa_31uo5sTF2vTx/view?usp=sharing")}>
                                        <FaArrowDown />
                                    </CourseDownload>
                                </CourseHead>
                                <Tooltip anchorSelect=".cfgm9" place="top">
                                    <CourseItem>Família d'energia i aigua</CourseItem>
                                    <CourseItem>DUAL GENERAL/INTENSIVA</CourseItem>
                                    <CourseItem>1 grup: 1r curs tarda, 2n curs tarda</CourseItem>
                                </Tooltip>
                            </Course>
                        </ListCourse>
                    </SectionBody>
                </Section>
                <Paragraf>
                    Tens més dubtes? Consulta tota la informació sobre els cicles formatius de grau mitjà a <Link href="https://triaeducativa.gencat.cat/ca/fp/grau-mitja/" target="_blank">triaeducativa.gencat.cat</Link>.
                </Paragraf>
            </Wrapper>
        </Content>
    );
}

export default OfertaEducativaCFGM;