
import styled from "styled-components";
import Wrapper from "../components/wrapper";
import { Helmet } from 'react-helmet';


const Content = styled.div`
`;

const H1 = styled.h1`
    margin-bottom: 24px;
    @media(max-width: 900px){
        font-size: 24px;
    }
`;

const H3 = styled.h3`
    @media(max-width: 900px){
        font-size: 18px;
    }
`;

const List = styled.ul`
    margin-bottom: 0;
`;

const Paragraf = styled.div<{ nopadding?: boolean }>`
    padding-bottom: ${props => props.nopadding ? '0' : '24px'};

    @media(max-width: 600px){
        font-size: 14px;
    }
`;

const Ask = styled.div`
    font-weight: bold;
`;



function PolicyPrivacy() {
    return (
        <Content >
            <Helmet>
                <title>Política de Cookies</title>
                <meta name="description" content="Política de Cookies" />
            </Helmet>
            <Wrapper padding="60px 30px 60px">
                <H1>Política de Cookies</H1>
                <H3>Què són les cookies?</H3>
                <Paragraf>
                    Les cookies són petits fitxers de text que s'emmagatzemen al teu dispositiu quan visites un lloc web. Aquestes cookies ajuden el lloc web a recordar informació sobre la teva visita, com ara el teu idioma preferit i altres configuracions. Això pot facilitar la teva pròxima visita i fer que el lloc web et sigui més útil. Les cookies tenen un paper important i, sense elles, utilitzar la web seria una experiència molt més frustrant.
                </Paragraf>

                <Ask>Quins tipus de cookies utilitzem al nostre lloc web?</Ask>
                <Paragraf>Al nostre lloc web, utilitzem cookies per a diversos propòsits, que inclouen:</Paragraf>

                <List>

                    <Paragraf><b>Cookies essencials:</b> Aquestes cookies són necessàries perquè puguis navegar pel nostre lloc web i utilitzar-ne les característiques. Sense aquestes cookies, els serveis que has sol·licitat no es poden proporcionar.</Paragraf>

                    <Paragraf><b>Cookies d'anàlisi:</b> Utilitzem cookies d'anàlisi, com Google Analytics (_ga), per recopilar informació sobre com els visitants utilitzen el nostre lloc web. Aquestes cookies ens permeten entendre com els usuaris interactuen amb el nostre lloc web, identificar àrees que necessiten millores i realitzar informes estadístics sobre l'activitat al lloc web. La informació recopilada és anònima i s'utilitza únicament amb finalitats estadístiques.</Paragraf>
                </List>

                <Ask>Cookies que utilitzem</Ask>
                <Paragraf> A continuació, detallem la cookie específica que utilitzem al nostre lloc web:</Paragraf>

                <List>
                    <Paragraf><b>Google Analytics (_ga):</b> Aquesta cookie és instal·lada per Google Analytics i s'utilitza per distingir els usuaris únics mitjançant l'assignació d'un número generat aleatòriament com a identificador de client. S'inclou en cada sol·licitud de pàgina en un lloc i s'utilitza per calcular les dades de visitants, sessions i campanyes per als informes d'anàlisi del lloc. La cookie _ga té una durada de 2 anys.
                        Ús de Firebase
                        El nostre lloc web també utilitza Firebase, una plataforma de desenvolupament d'aplicacions de Google que ens ajuda a proporcionar i millorar els nostres serveis. Firebase pot recopilar dades relacionades amb l'ús de l'aplicació, el rendiment i la interacció dels usuaris amb el contingut. La informació recopilada a través de Firebase s'utilitza per millorar la funcionalitat i l'experiència de l'usuari.</Paragraf>
                </List>

                <Ask>Gestió de cookies</Ask>
                <Paragraf>Pots gestionar les cookies configurant el teu navegador per acceptar, rebutjar o eliminar cookies. Cada navegador és diferent, per la qual cosa et recomanem que consultis el menú d'ajuda del teu navegador per saber com canviar les teves preferències de cookies.</Paragraf>

                <Paragraf>Tingues en compte que, si decideixes desactivar les cookies, és possible que algunes parts del nostre lloc web no funcionin correctament.</Paragraf>

                <Ask>Actualitzacions d'aquesta política de cookies</Ask>
                <Paragraf>Podem actualitzar la nostra política de cookies de tant en tant per reflectir canvis en les nostres pràctiques i serveis. Et recomanem que revisis periòdicament aquesta pàgina per estar al corrent de com utilitzem les cookies.</Paragraf>

                <Ask>Contacte</Ask>
                <Paragraf>Si tens alguna pregunta o preocupació sobre la nostra política de cookies, no dubtis a posar-te en contacte amb nosaltres a través de oficines@iesemt.net.</Paragraf>

            </Wrapper >
        </Content >
    );
}

export default PolicyPrivacy;