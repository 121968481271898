
import styled from "styled-components";
import Wrapper from "../../components/wrapper";
import { Helmet } from "react-helmet";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const Content = styled.div``;

const Container = styled.div`
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const Sections = styled.div`
    padding: 0 0 24px;
`;

const Title = styled.h1`
    text-align: center;
    @media(max-width: 900px){
        font-size: 24px;
        margin-bottom: 0;
    }
`;

const Paragraf = styled.div`
    padding-bottom: 24px;

    @media(max-width: 600px){
        font-size: 14px;
    }
`;

const SectionWrapper = styled.div`
    > div {
        margin-bottom: 24px;
    }
`;

const ImageGalleryWrapper = styled.div``;

const YoutubeWrapper = styled.div`

    > iframe {
        height:600px;
    }
    @media(max-width: 600px){
        > iframe {
            height: 200px;
        }
    }
`;

const SubTitle = styled.h3`
    text-align: center;
    margin-bottom: 18px;

    @media(max-width: 900px){
        font-size: 18px;
    }
    @media(max-width: 600px){
        margin-bottom: 0;
    }
`;

const Gallery = styled.div`
    display: flex;
    flex-direction: row;
    gap: 24px;

    @media(max-width: 1200px){
        flex-direction: column;
    }
`;


function Instalacions() {

    const images = [
        {
            original: "https://picsum.photos/id/1018/1000/600/",
            thumbnail: "https://picsum.photos/id/1018/250/150/",
        },
        {
            original: "https://picsum.photos/id/1015/1000/600/",
            thumbnail: "https://picsum.photos/id/1015/250/150/",
        },
        {
            original: "https://picsum.photos/id/1019/1000/600/",
            thumbnail: "https://picsum.photos/id/1019/250/150/",
        },
        {
            original: "https://picsum.photos/id/1019/1000/600/",
            thumbnail: "https://picsum.photos/id/1019/250/150/",
        },
        {
            original: "https://picsum.photos/id/1019/1000/600/",
            thumbnail: "https://picsum.photos/id/1019/250/150/",
        },
        {
            original: "https://picsum.photos/id/1019/1000/600/",
            thumbnail: "https://picsum.photos/id/1019/250/150/",
        },
        {
            original: "https://picsum.photos/id/1019/1000/600/",
            thumbnail: "https://picsum.photos/id/1019/250/150/",
        },
    ];

    return (
        <Content>
            <Wrapper padding="60px 30px 60px">
                <Container>
                    <Title>Instal·lacions</Title>
                    <Helmet>
                        <title>Instal·lacions</title>
                        <meta name="description" content="Instal·lacions" />
                    </Helmet>
                    <Sections>
                        <Paragraf>
                            L’institut EMT és un centre que consta de dos edificis comunicats. A l’edifici de l’entrada principal es troba la consergeria, les oficines del centre, la sala d’actes i la biblioteca.
                        </Paragraf>
                        <SectionWrapper>
                            <Gallery>
                                <ImageGalleryWrapper>
                                    <SubTitle>Aules ordinaries</SubTitle>
                                    <ImageGallery thumbnailPosition="right" items={images} />
                                </ImageGalleryWrapper>
                                <ImageGalleryWrapper>
                                    <SubTitle>Aules especialitzades</SubTitle>
                                    <ImageGallery thumbnailPosition="right" items={images} />
                                </ImageGalleryWrapper>
                            </Gallery>
                            <YoutubeWrapper>
                                <SubTitle>Tour virtual</SubTitle>
                                <iframe
                                    width="100%"
                                    height="400px"
                                    src="https://www.youtube.com/embed/svoLiWKRcOo?si=kRR25yB7-fT8QRfJ"
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin"
                                    allowFullScreen
                                    className="rounded-lg shadow-lg"
                                ></iframe>
                            </YoutubeWrapper>
                        </SectionWrapper>
                    </Sections>
                </Container>
            </Wrapper>
        </Content >
    );
}

export default Instalacions;