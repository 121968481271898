
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { ROUTES } from '../constants/routes';
import Home from "../pages/home";
import Search from "../pages/search";
import Admin from "../pages/admin";
import DocumentacionEstrategica from "../pages/centre/documentacio-estrategica";
import PoliticaQualitat from "../pages/centre/politica-qualitat";
import Historia from "../pages/centre/historia";
import PreinscripcioIMatricula from "../pages/secretaria/preinscripcio-i-matricula";
import Tramits from "../pages/secretaria/tramits";
import NotFound from "../pages/not-found";
import Tutors from "../pages/tutors";
import Students from '../pages/students';
import Projects from "../pages/projects";
import TramitsConvalidacioEso from "../pages/secretaria/tramits/tramits-convalidacio-eso";
import TramitsConvalidacioBatxillerat from "../pages/secretaria/tramits/tramits-convalidacio-batx";
import TramitsConvalidacionsFp from "../pages/secretaria/tramits/tramits-convalidacio-cf";
import TramitsCertificatNivellCatala from "../pages/secretaria/tramits/tramits-nivell-catala";
import OfertaEducativaBatxillerat from "../pages/oferta-educativa/batxillerat";
import OfertaEducativaESO from "../pages/oferta-educativa/eso";
import PolicyCookies from "../pages/policy-cookies";
import PolicyPrivacy from "../pages/policy-privacy";
import OfertaEducativaCFGM from "../pages/oferta-educativa/cfgm";
import OfertaEducativaCFGS from "../pages/oferta-educativa/cfgs";
import Instalacions from "../pages/centre/instalacions";



export const RouterHubContent = styled.div`
  min-height: 700px;
`;

export default function RouterHub() {
  return (
    <RouterHubContent>
      <Routes>
        <Route path={ROUTES.Admin} element={<Admin />} />
        <Route path={ROUTES.Home} element={<Home />} />
        <Route path={ROUTES.Search} element={<Search />} />
        <Route path={ROUTES.Historia} element={<Historia />} />
        <Route path={ROUTES.PoliticaQualitat} element={<PoliticaQualitat />} />
        <Route path={ROUTES.DocumentacioEstrategica} element={<DocumentacionEstrategica />} />
        <Route path={ROUTES.OfertaEducativaESO} element={<OfertaEducativaESO />} />
        <Route path={ROUTES.OfertaEducativaBatxillerat} element={<OfertaEducativaBatxillerat />} />
        <Route path={ROUTES.OfertaEducativaCFGM} element={<OfertaEducativaCFGM />} />
        <Route path={ROUTES.OfertaEducativaCFGS} element={<OfertaEducativaCFGS />} />
        <Route path={`${ROUTES.PreinscripcioMatricula}/:type?`} element={<PreinscripcioIMatricula />} />
        <Route path={ROUTES.TramitsConvalidacioEso} element={<TramitsConvalidacioEso />} />
        <Route path={ROUTES.TramitsConvalidacioBatxillerat} element={<TramitsConvalidacioBatxillerat />} />
        <Route path={ROUTES.TramitsConvalidacioFp} element={<TramitsConvalidacionsFp />} />
        <Route path={ROUTES.TramitsNivellCatala} element={<TramitsCertificatNivellCatala />} />
        <Route path={ROUTES.Tramits} element={<Tramits />} />
        <Route path={ROUTES.Home} element={<Home />} />
        <Route path={ROUTES.Profesorat} element={<Tutors />} />
        <Route path={ROUTES.Alumnat} element={<Students />} />
        <Route path={ROUTES.Projectes} element={<Projects />} />
        <Route path={ROUTES.PolicyCookies} element={<PolicyCookies />} />
        <Route path={ROUTES.PolicyPrivacy} element={<PolicyPrivacy />} />
        <Route path={ROUTES.Instalacions} element={<Instalacions />} />
        <Route path={ROUTES.NotFound} element={<NotFound />} />
      </Routes>
    </RouterHubContent>
  );
}