
import styled from "styled-components";
import Wrapper from "../../components/wrapper";
import Box from "../../components/box";
import { useState } from "react";
import { Helmet } from 'react-helmet';

const Content = styled.div`
`;

const H1 = styled.h1`
    padding-bottom: 24px;
    @media(max-width: 900px){
        font-size: 24px;
    }
    @media(max-width: 600px){
        line-height: 28px;
    }
`;

const H2 = styled.h2`
    padding-bottom: 24px;

    @media(max-width: 900px){
        font-size: 21px;
    }
`;

const List = styled.ul`
    margin-bottom: 0;
`;

const Item = styled.li`

    @media(max-width: 600px){
        font-size: 14px;
    }
`;

const Paragraf = styled.div<{ nopadding?: boolean }>`
    padding-bottom: ${props => props.nopadding ? '0' : '24px'};

    @media(max-width: 600px){
        font-size: 14px;
    }
`;

const Menu = styled.div`
    display: flex; 
    align-items: center; 
    justify-content: center;
    flex-direction: row;
    margin: 24px 0;


    @media(max-width: 600px){
        justify-content: center;
    }
`;

const MenuItem = styled.div<{ selected: boolean }>`
    padding: 12px 24px;
    color: ${props => props.selected ? '#fff' : 'inherit'};
    background-color: ${props => props.selected ? '#c82d2d' : '#fff'};
    font-weight: ${props => props.selected ? 'bold' : 'initial'};
    cursor: pointer;
    border-right: 1px solid #ccc;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 0px 20px 6px;
    text-align: center;
    min-width: 75px;


    :first-child{
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
    }

    :last-child{
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
        border-right: initial;
    }


    @media(max-width: 600px){
        padding: 12px;
    }
`;

const MenuContent = styled.div`
    display: flex;
    gap: 24px;

    @media (max-width: 900px){
        flex-direction: column;
    }
`;

const MenuBody = styled.div`
    display: flex;
    justify-content: start; 
    flex-direction: column;


`;

const MenuTitle = styled.div`
    font-weight: bold;
    padding-bottom: 12px;
`;

const Optativa = styled.div`


`;

const Projecte = styled.div``;

const Table = styled.div`
    padding: 24px 0;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: fit-content;
    border-bottom: 1px solid #dddddd;
    padding: 4px 0;

    :last-child{
        border-bottom: initial;
    }

    @media(max-width: 600px){
        padding: 2px;
    }
`;

const Cell = styled.div<{ width?: string, color?: string }>`
    background-color: ${props => !props.color ? '#fff' : props.color};
    width: ${props => !props.width ? '50px' : `${props.width}px`};
    padding: 4px 4px 4px 8px;

    @media(max-width: 600px){
        font-size: 14px;
        width: ${props => !props.width ? '25px' : `${parseInt(props.width) - 100}px`};
        padding: 2px;
    }
`;

const LegendList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media (max-width: 600px){
        gap: 4px;
        font-size: 14px;
    }
`;

const Legend = styled.div`
    display: flex;
    gap: 12px;
    justify-content: start; 
    align-items: center;
`;

const LegendColor = styled.div<{ color?: string }>`
    background-color: ${props => !props.color ? '#fff' : props.color};
    width: 25px;
    height: 25px;
`;

const LegendText = styled.div``;

const Section = styled.div`
    padding-bottom: 30px;
`;

const SectionHead = styled.div`
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 9px;
`;

const SectionBody = styled.div`
    @media(max-width: 600px){
        font-size: 14px;
    }
`;


enum Curs {
    primer,
    segon,
    tercer,
    quart
}

function OfertaEducativaESO() {
    const [curs, setCurs] = useState<Curs>(Curs.primer);
    return (
        <Content >
            <Helmet>
                <title>Educació Secundària Obligatòria (ESO)</title>
                <meta name="description" content="Educació Secundària Obligatòria (ESO)" />
            </Helmet>
            <Wrapper padding="60px 30px 60px">
                <H1>Educació Secundària Obligatòria (ESO)</H1>
                <Paragraf>
                    L'ESO és una etapa d'ensenyament obligatori, estructurada en quatre cursos escolars que pretén la formació bàsica per tal de garantir a tot l'alumnat una bona orientació i preparació per al seu futur.
                    El nostre horari és de jornada continuada de 8.00 a 14.30 hores amb dos patis de 20 minuts a 1r i 2n o un de 30 minuts a 3r i 4t.
                    A més de desenvolupar els coneixements, capacitats, habilitats i actituds necessàries, els nois i les noies han d'aprendre a integrar tots aquests recursos per assolir fites personals i esdevenir persones responsables, autònomes i compromeses socialment, per incorporar-se a la vida adulta de manera satisfactòria.
                    És prioritari, al llarg de l'etapa, acompanyar l'alumnat en el seu procés d'aprenentatge i el seu progrés personal.
                </Paragraf>
                <H2>Organització general</H2>
                <Section>
                    <SectionHead>Acció tutorial</SectionHead>
                    <SectionBody>
                        L'acció tutorial a l'ESO se centra en aquells aspectes que permeten un desenvolupament i coneixement personal de l'alumne/a, així com una orientació en les seves activitats escolars i acadèmiques. També s'incideix en el seguiment individualitzat de l'alumne/a amb un contacte permanent amb les famílies.
                        Un dels objectius principals és fomentar l'autoconeixement i l'autoacceptació dels alumnes, cuidant la seva autoestima, afavorint l'autocontrol i la presa de decisions.
                        Per a fer més eficaç l'acció tutorial, s'intenta establir la continuïtat dels tutors entre els nivells de 1r i 2n d'ESO, i entre 3r i 4t d'ESO.
                    </SectionBody>
                </Section>
                <Section>
                    <SectionHead>Equips de nivell</SectionHead>
                    <SectionBody>
                        El funcionament del professorat gira al voltant dels equips de nivell.
                        L'equip de nivell està format pels tutors/es i per tot el professorat que imparteix classe a un mateix nivell de l'ESO: es reuneixen una vegada a la setmana per fer el seguiment de les activitats pedagògiques i del funcionament de les classes. Prioritzem que hi hagi un professor de cada matèria per nivell.
                    </SectionBody>
                </Section>
                <Section>
                    <SectionHead>Agrupacions de curs</SectionHead>
                    <SectionBody>
                        L'Institut té assignades tres línies d'ESO, de 1r a 4t.
                        A 1r i 2n d'ESO les línies es convertiran en cinc grups i, a 3r i 4t d'ESO, en quatre, per tal de reduir ràtios, facilitar la millor adaptació a l'Institut, al treball cooperatiu, a l'aprofundiment en els continguts i a la millora dels aprenentatges.
                    </SectionBody>
                </Section>
                <Section>
                    <SectionHead>Llengües estrangeres</SectionHead>
                    <SectionBody>
                        Incidim en l'estudi de l'anglès a partir de diferents activitats i projectes incorporats en altres matèries.
                        Completem el currículum de llengües estrangeres amb l'aprenentatge del francès com a segona llengua estrangera optativa.
                    </SectionBody>
                </Section>
                <Section>
                    <SectionHead>A destacar?</SectionHead>
                    <SectionBody>
                        <Paragraf>

                            Sortides o activitats de convivències, que ens permetrà treballar la cohesió del grup i el treball cooperatiu.
                        </Paragraf>
                        <Paragraf>
                            El "Projecte de vida" s'emmarca dins del Pla d'Acció Tutorial i promou l'orientació educativa, entesa com a orientació personal, social, acadèmica i professional al llarg de tota l'ESO.
                        </Paragraf>
                        <Paragraf>
                            En l'hàbit lector, una hora a la setmana, l'alumnat es trasllada a la biblioteca on es troba en un espai molt adient per promocionar l'interès per la lectura en les tres llengües: català, castellà i anglès.
                        </Paragraf>
                        <Paragraf>
                            Amb el Projecte Magraner-Sol es dona l'oportunitat de conèixer el món laboral a aquells alumnes que presenten dificultats en l'aprenentatge i té continuïtat amb els Tastets d'oficis a 4t.
                        </Paragraf>
                        <Paragraf>
                            L'alumnat disposa d'una gran varietat d'optatives, impartides per professors especialitzats, per tal d'encaminar la seva formació acadèmica en funció de les matèries escollides. Aprofitant els nostres tallers de l'FP ofertem una optativa de taller a 4t d'ESO en el qual es fan pràctiques de mecànica, d'automoció i d'electricitat.
                        </Paragraf>
                        <Paragraf>

                            Ofertem la matèria d'emprenedoria i la creació de nous projectes com a aproximació al món empresarial.

                        </Paragraf>
                        <Paragraf>
                            A la tutoria de 4t d'ESO s'orienta l'alumnat sobre els diferents itineraris educatius / formatius (estudis postobligatoris). Entre les diferents activitats d'orientació destaca: el "Pràcticum". A partir de l'oferta d'estudis postobligatoris l'alumnat fa un "tastet" pràctic per conèixer els cicles formatius en els quals està interessat. Es realitzen visites a empreses, al "Fira-Guia't" i xerrades per part dels alumnes de Batxillerat i Cicles Formatius.

                        </Paragraf>
                        <Paragraf nopadding={true}>
                            El centre disposa de diferents Projecte ERASMUS que tenen com a objectiu promoure la mobilitat per a l'aprenentatge de l'alumnat, així com la cooperació, la qualitat, la inclusió i l'equitat, l'excel·lència, la creativitat i la innovació.
                        </Paragraf>
                    </SectionBody>
                </Section>

                <Section>
                    <SectionHead>Organització curricular </SectionHead>
                    <SectionBody>
                        <Paragraf nopadding={true}>
                            <Paragraf>
                                Cada curs comença amb unes sessions per acompanyar l’inici de curs i millorar l’adaptació de l’alumnat.
                            </Paragraf>
                            <List>
                                <Item><b>1r ESO - ARRENQUEM L’ESO</b></Item>
                                <Item><b>2n ESO - RECORDEM</b></Item>
                                <Item><b>3r ESO - NO OBLIDEM</b></Item>
                                <Item><b>4t ESO - ENS ACOMIADEM</b></Item>
                            </List>
                        </Paragraf>
                        <Table>
                            <Row>
                                <Cell width='250'></Cell>
                                <Cell color='#ddd' >1r </Cell>
                                <Cell color='#ddd'>2n</Cell>
                                <Cell color='#ddd'>3r</Cell>
                                <Cell color='#ddd'>4t</Cell>
                            </Row>
                            <Row>
                                <Cell width='250' color='#078ea673'>Llengua catalana</Cell>
                                <Cell>3</Cell>
                                <Cell>2</Cell>
                                <Cell>2</Cell>
                                <Cell>3</Cell>
                            </Row>
                            <Row>
                                <Cell width='250' color='#078ea673'>Llengua castellana</Cell>
                                <Cell>3</Cell>
                                <Cell>2</Cell>
                                <Cell>2</Cell>
                                <Cell>3</Cell>
                            </Row>
                            <Row>
                                <Cell width='250' color='#078ea673'>LLengua estrangera</Cell>
                                <Cell>3</Cell>
                                <Cell>3</Cell>
                                <Cell>2</Cell>
                                <Cell>3</Cell>
                            </Row>
                            <Row>
                                <Cell width='250' color='#078ea673'>Socials: geografia i història</Cell>
                                <Cell>2</Cell>
                                <Cell>3</Cell>
                                <Cell>3</Cell>
                                <Cell>2</Cell>
                            </Row>
                            <Row>
                                <Cell width='250' color='#078ea673' >Naturals: bio i geo</Cell>
                                <Cell>2</Cell>
                                <Cell>0</Cell>
                                <Cell>2</Cell>
                                <Cell>0</Cell>
                            </Row>
                            <Row>
                                <Cell color='#078ea673' width='250'>Naturals: física i química</Cell>
                                <Cell>0</Cell>
                                <Cell>2</Cell>
                                <Cell>2</Cell>
                                <Cell>0</Cell>
                            </Row>
                            <Row>
                                <Cell color='#078ea673' width='250'>Matemàtiques</Cell>
                                <Cell>3</Cell>
                                <Cell>3</Cell>
                                <Cell>3</Cell>
                                <Cell>4</Cell>
                            </Row>
                            <Row>
                                <Cell width='250' color='#078ea673'>Educació física</Cell>
                                <Cell>2</Cell>
                                <Cell>2</Cell>
                                <Cell>2</Cell>
                                <Cell>2</Cell>
                            </Row>
                            <Row>
                                <Cell width='250' color='#078ea673'>Música</Cell>
                                <Cell>2</Cell>
                                <Cell>2</Cell>
                                <Cell>0</Cell>
                                <Cell>0</Cell>
                            </Row>
                            <Row>

                                <Cell width='250' color='#078ea673'>Visual i plàstica</Cell>
                                <Cell>2</Cell>
                                <Cell>0</Cell>
                                <Cell>2</Cell>
                                <Cell>0</Cell>
                            </Row>
                            <Row>

                                <Cell width='250' color='#078ea673'>Tecnologia i digitalització</Cell>
                                <Cell>0</Cell>
                                <Cell>3</Cell>
                                <Cell>2</Cell>
                                <Cell>0</Cell>
                            </Row>
                            <Row>

                                <Cell width='250' color='#078ea673'>CIVE</Cell>
                                <Cell>0</Cell>
                                <Cell>0</Cell>
                                <Cell>0</Cell>
                                <Cell>1</Cell>
                            </Row>
                            <Row>
                                <Cell width='250' color='#078ea673'>Optativa</Cell>
                                <Cell>2</Cell>
                                <Cell>2</Cell>
                                <Cell>2</Cell>
                                <Cell>6</Cell>
                            </Row>
                            <Row >

                                <Cell width='250' color='#078ea673'>Tutoria</Cell>
                                <Cell>1</Cell>
                                <Cell>1</Cell>
                                <Cell>1</Cell>
                                <Cell>1</Cell>
                            </Row>
                            <Row>
                                <Cell width='250' color='#f69c60'>Hàbit lector</Cell>
                                <Cell>1</Cell>
                                <Cell>1</Cell>
                                <Cell>1</Cell>
                                <Cell>1</Cell>
                            </Row>
                            <Row >
                                <Cell width='250' color='#f69c60'>Projectes</Cell>
                                <Cell>4</Cell>
                                <Cell>4</Cell>
                                <Cell>4</Cell>
                                <Cell>4</Cell>
                            </Row>
                            <Row>
                                <Cell width='250' ></Cell>
                                <Cell color='#ffe69c'>30</Cell>
                                <Cell color='#ffe69c'>30</Cell>
                                <Cell color='#ffe69c'>30</Cell>
                                <Cell color='#ffe69c'>30</Cell>
                            </Row>
                        </Table>

                        <LegendList>
                            <Legend>
                                <LegendColor color="#078ea673" />
                                <LegendText>MATÈRIES OBLIGATÒRIES</LegendText>
                            </Legend>
                            <Legend>
                                <LegendColor color="#f69c60" />
                                <LegendText>GESTIÓ AUTÒNOMA</LegendText>
                            </Legend>
                        </LegendList>
                    </SectionBody>
                </Section>

                <Section>
                    <SectionHead>Optatives i projectes</SectionHead>
                    <SectionBody>
                        <Menu>
                            <MenuItem
                                selected={curs === Curs.primer}
                                onClick={() => setCurs(Curs.primer)}>
                                1r
                            </MenuItem>
                            <MenuItem
                                selected={curs === Curs.segon}
                                onClick={() => setCurs(Curs.segon)}>
                                2n
                            </MenuItem>
                            <MenuItem
                                selected={curs === Curs.tercer}
                                onClick={() => setCurs(Curs.tercer)}>
                                3r
                            </MenuItem>
                            <MenuItem
                                selected={curs === Curs.quart}
                                onClick={() => setCurs(Curs.quart)}>
                                4t
                            </MenuItem>
                        </Menu>

                        <MenuContent>
                            <Box backgroundColor="#f9ddc6">
                                <MenuTitle>Optatives</MenuTitle>
                                <MenuBody>
                                    <Optativa>
                                        {curs === Curs.primer &&
                                            <List>
                                                <Item>Francès (Anual)</Item>
                                                <Item>Robòtica i programació (Anual)</Item>
                                                <Item>PAC (semestral) (de les 3 ofertades l'alumne n'ha de triar 2)</Item>
                                                <List>
                                                    <Item>Acta est Fabula (Teatre en català)</Item>
                                                    <Item>Blod bowl (Socials)</Item>
                                                    <Item>Projecte enginy (Tecnologia)</Item>
                                                </List>
                                            </List>
                                        }
                                        {curs === Curs.segon &&
                                            <List>
                                                <Item>Francès (Anual)</Item>
                                                <Item>EMT Today (Anual) (Anglès)</Item>
                                                <Item>PAC (semestral) (de les 3 ofertades l'alumne n'ha de triar 2)</Item>
                                                <List>
                                                    <Item>Literatura: Violència i pau (Castellà)</Item>
                                                    <Item>EMT ludicus (Socials)</Item>
                                                    <Item>Vallèsbot (Tecnologia)</Item>
                                                </List>
                                            </List>
                                        }
                                        {curs === Curs.tercer &&
                                            <List>
                                                <Item>Francès (Anual)</Item>
                                                <Item>Cultura clàssica (Anual) (Català)</Item>
                                                <Item>PAC (semestral)</Item>
                                                <List>
                                                    <Item>3 d’art (Socials)</Item>
                                                    <Item>Escultura volumètrica (VIP)</Item>
                                                </List>
                                            </List>
                                        }
                                        {curs === Curs.quart &&
                                            <List>
                                                <Item>Bloc 1: Economia, Llatí, Física i Química, Digitalització (Simulador + Tesla)</Item>
                                                <Item>Bloc 2: Emprenedoria, Filosofia, Biologia i Geologia, FOLP (Taller automoció, mecànica i electricitat)</Item>
                                                <Item>Bloc 3: Expressió artística, Segona llengua estrangera Francès, Tecnologia, Cultura Científica</Item>
                                            </List>
                                        }
                                    </Optativa>
                                </MenuBody>
                            </Box>
                            <Box backgroundColor="#f9ddc6">
                                <MenuTitle>Projectes</MenuTitle>
                                <MenuBody>
                                    <Projecte>
                                        {curs === Curs.primer &&
                                            <List>
                                                <Item>Congrés I (ciències)</Item>
                                                <Item>Via Romana (Socials)</Item>
                                                <Item>Camí del càlcul (Matemàtiques)</Item>
                                                <Item>Disseny de videojocs i d’una IA (Tecnologia)</Item>
                                                <Item>Democràcia digital (Castellà)</Item>
                                                <Item>Ser i estar (CIVE): Treballar el jo</Item>
                                            </List>
                                        }
                                        {curs === Curs.segon &&
                                            <List>
                                                <Item>CONGRÉS Posa't en òrbita (Ciències) + Món microscòpic i cosmos (matemàtiques)</Item>
                                                <Item>Comics con clase (Castellà)</Item>
                                                <Item>Max in love (Ciències)</Item>
                                                <Item>Art i matemàtiques (Matemàtiques) </Item>
                                                <Item>3D impressió (Tecnologia)</Item>
                                                <Item>Identitat i diferència (CIVE): Treballar el nosaltres</Item>
                                            </List>
                                        }
                                        {curs === Curs.tercer &&
                                            <List>
                                                <Item>Congrés III- Energies renovables (Ciències i Tecnologia)</Item>
                                                <Item>Programació de drons (Tecnologia)</Item>
                                                <Item>Coaching educatiu - Taller aprèn (CIVE)</Item>
                                                <Item>Vida saludable (Ciències)</Item>
                                                <Item>Granollers emprèn (Emprenedoria) (Administratiu)</Item>
                                                <Item>Benestar financer (Administratiu)</Item>
                                            </List>
                                        }
                                        {curs === Curs.quart &&
                                            <List>
                                                <Item>Acabem L'ESO- (ANUAL) (Tutories)</Item>
                                                <List>
                                                    <Item>Orientació</Item>
                                                    <Item>Graduació</Item>
                                                </List>
                                                <Item>Granollerada/Barcelonada (Tecnologia i socials)</Item>
                                                <Item>Anàlisi Crítica (Humanitats)</Item>
                                                <Item>Retrofotografia  i fonaments històrics (Socials)</Item>

                                            </List>
                                        }
                                    </Projecte>
                                </MenuBody>
                            </Box>
                        </MenuContent>
                    </SectionBody>
                </Section>

            </Wrapper >
        </Content >
    );
}

export default OfertaEducativaESO;