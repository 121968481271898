
import styled from "styled-components";
import Wrapper from "../../components/wrapper";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "react-bootstrap";
import theme from "../../constants/theme";
import Spinner from 'react-bootstrap/Spinner';

import { useEffect, useState } from "react";
import { ConfigApi } from "../../api/config";
import { IPreinscripcioConfig } from '../../types/Config';
import { ItinerariEnum } from '../../constants/enums';
import Timeline from "../../components/preinscripcions/timeline";
import { ROUTES } from "../../constants/routes";
import { Helmet } from 'react-helmet';
import { FaInfoCircle } from "react-icons/fa";
import { RiCloseCircleFill } from "react-icons/ri";

const Content = styled.div`
    padding: 48px 0;

    @media(max-width: 900px){
        padding: 0;
    }
`;

const Titulo = styled.h1`
    padding-bottom: 24px;
    @media(max-width: 900px){
        font-size: 24px;
        line-height: 24px;
    }
`;

const Paragraf = styled.div`
    padding: 16px;
    background-color: #ffc8c8;
    border-radius: 12px;
    display: flex; 
    flex-direction: row;
    gap: 12px;
    max-width: 600px;

    @media(max-width: 600px){
        font-size: 14px;
    }
`;

const ParagrafIcon = styled.div`

`;

const ParagrafText = styled.div``;

const Link = styled(Alert.Link)`
    color: ${theme.colors.link};
`;

const SpinnerWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex; 
    justify-content: center;
    align-items: center;
`;

const Result = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    gap: 60px;

    @media(max-width: 1200px){
        flex-direction: column;
        gap: 12px;
    }

`

const Instruccions = styled.div`
    width: 40%;
    background-color: #cfe3ff;
    padding: 16px 16px 48px;
    border-radius: 12px;

    @media(max-width: 1200px){
        width: 100%;
        padding: 16px;
    }

    @media(max-width: 600px){
        font-size: 14px;
    }
`;

const InstruccionsIcon = styled.div`
    display: flex;
    align-items: center; 
    justify-content: center;
    padding: 12px 0;


    @media(max-width: 600px){
        padding: 0;
    }
`;


const TimelineWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: start;
    align-content: center;

    height: 400px;
    overflow: scroll;
`;



const Menu = styled.div`
    display: flex; 
    align-items: center; 
    justify-content: start;
    flex-direction: row;
    margin-bottom: 24px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 0px 20px 6px;
    width: fit-content;
    border-radius: 12px;


    @media(max-width: 600px){
        justify-content: center;
    }
`;

const MenuItem = styled.div<{ selected: boolean }>`
    padding: 12px 24px;
    color: ${props => props.selected ? '#fff' : 'inherit'};
    background-color: ${props => props.selected ? '#c82d2d' : '#fff'};
    font-weight: ${props => props.selected ? 'bold' : 'initial'};
    cursor: pointer;
    border-right: 1px solid #ccc;
    
    text-align: center;
    min-width: 127px;

    :first-child{
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
    }

    :last-child{
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
        border-right: initial;
    }

    @media(max-width: 600px){
        min-width: initial;

        :first-child{
            padding-left: 24px; 
        }

        :last-child{
            padding-right: 24px; 
        }
    }


    @media(max-width: 600px){
        padding: 12px;
    }
`;


function PreinscripcioIMatricula() {
    const { type } = useParams();
    const [preinscripcio, setPreinscripcio] = useState<IPreinscripcioConfig | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    useEffect(() => {
        ConfigApi.GetById("preinscripcio")
            .then((x: IPreinscripcioConfig) => setPreinscripcio(x))
            .catch(x => console.log(x))
            .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        if (type && !(type in ItinerariEnum)) navigate('/not-found/404');
    }, [type, navigate]);

    const getItinerariEnum = (value: string): ItinerariEnum | undefined => {
        if (!Object.keys(ItinerariEnum).includes(value)) return undefined;
        return ItinerariEnum[value as keyof typeof ItinerariEnum];
    }

    const getInstruccions = () => {
        if (type === ItinerariEnum[ItinerariEnum.eso]) return preinscripcio?.InstruccionsUrlESO;
        if (type === ItinerariEnum[ItinerariEnum.batxillerat]) return preinscripcio?.InstruccionsUrlBatxillerat;
        if (type === ItinerariEnum[ItinerariEnum.cfgm]) return preinscripcio?.InstruccionsUrlCFGM;
        if (type === ItinerariEnum[ItinerariEnum.cfgs]) return preinscripcio?.InstruccionsUrlCFGS;
    }

    const getGencat = () => {
        if (type === ItinerariEnum[ItinerariEnum.eso]) return preinscripcio?.GencatESO;
        if (type === ItinerariEnum[ItinerariEnum.batxillerat]) return preinscripcio?.GencatBatxillerat;
        if (type === ItinerariEnum[ItinerariEnum.cfgm]) return preinscripcio?.GencatCFGM;
        if (type === ItinerariEnum[ItinerariEnum.cfgs]) return preinscripcio?.GencatCFGS;
    }

    const getDocument = () => {
        if (type === ItinerariEnum[ItinerariEnum.eso]) return preinscripcio?.DocumentacioESO;
        if (type === ItinerariEnum[ItinerariEnum.batxillerat]) return preinscripcio?.DocumentacioBatxillerat;
        if (type === ItinerariEnum[ItinerariEnum.cfgm]) return preinscripcio?.DocumentacioCFGM;
        if (type === ItinerariEnum[ItinerariEnum.cfgs]) return preinscripcio?.DocumentacioCFGS;
    }

    const handleClick = (route: string) => {
        navigate(route);
    }

    return (
        <Content >
            <Helmet>
                <title>Preinscripció i Matrícula</title>
                <meta name="description" content="Preinscripció i Matrícula" />
            </Helmet>
            <Wrapper>
                <Titulo>Preinscripció i Matrícula {/*type?.toUpperCase()*/}</Titulo>
                <Menu>
                    <MenuItem
                        selected={type === ItinerariEnum[ItinerariEnum.eso]}
                        onClick={() => handleClick(`${ROUTES.PreinscripcioMatricula}/eso`)}>
                        ESO</MenuItem>
                    <MenuItem
                        selected={type === ItinerariEnum[ItinerariEnum.batxillerat]}
                        onClick={() => handleClick(`${ROUTES.PreinscripcioMatricula}/batxillerat`)}>
                        Batxillerat
                    </MenuItem>
                    <MenuItem
                        selected={type === ItinerariEnum[ItinerariEnum.cfgm]}
                        onClick={() => handleClick(`${ROUTES.PreinscripcioMatricula}/cfgm`)}>
                        CFGM</MenuItem>
                    <MenuItem
                        selected={type === ItinerariEnum[ItinerariEnum.cfgs]}
                        onClick={() => handleClick(`${ROUTES.PreinscripcioMatricula}/cfgs`)}>
                        CFGS</MenuItem>
                </Menu>
                {loading ?
                    <SpinnerWrapper>
                        <Spinner variant="danger" />
                    </SpinnerWrapper>
                    :
                    (type === ItinerariEnum[ItinerariEnum.eso] && preinscripcio?.ESO === false) ||
                        (type === ItinerariEnum[ItinerariEnum.batxillerat] && preinscripcio?.Batxillerat === false) ||
                        (type === ItinerariEnum[ItinerariEnum.cfgm] && preinscripcio?.CFGM === false) ||
                        (type === ItinerariEnum[ItinerariEnum.cfgs] && preinscripcio?.CFGS === false) ?
                        <Paragraf>
                            <ParagrafIcon>
                                <RiCloseCircleFill size="30px" color="#d73131" />
                            </ParagrafIcon>
                            <ParagrafText>
                                La informació de preinscripcions i matrícula pel curs {new Date().getFullYear()}/{(new Date().getFullYear() + 1).toString().slice(-2)} estarà disponible properament.
                                Per a més informació, també podeu consultar a <Link
                                    target="_blank"
                                    href="https://preinscripcio.gencat.cat/ca/inici/index.html">gencat.cat
                                </Link>
                            </ParagrafText>
                        </Paragraf>
                        :
                        <Result>
                            <Instruccions>
                                <InstruccionsIcon>
                                    <FaInfoCircle size="30px" color="#186cf2" />
                                </InstruccionsIcon>
                                La sol·licitud és telemàtica. Llegiu les <Link target="_blank" href={getInstruccions()}>instruccions</Link> sobre el procés de preinscripció.
                                <br />
                                <br />
                                <b>Només en casos excepcionals</b>: es pot demanar cita prèvia presencial per a la preinscripció a <Link href={getGencat()}>gencat.cat</Link>. El dia de la cita s'haurà de presentar la <Link target="_blank" href={getDocument()}>documentació necessària</Link>.
                            </Instruccions>
                            <TimelineWrapper>
                                <Timeline itinerari={getItinerariEnum(type!)!} />
                            </TimelineWrapper>
                        </Result>
                }
            </Wrapper>
        </Content >
    );
}

export default PreinscripcioIMatricula;